import React from 'react';
import SearchBar from './components/searchbar/SearchBar';
import PopularBar from './features/popularSection/PopularBar';
import Posts from './features/Home/';

import './App.css';

function App() {
  return (
    <div className='App'>
      <header className='m-5 sticky-top'>
        <SearchBar />
      </header>

      <main className='m-5'>
        <div className='d-flex justify-content-evenly'>
          <div className='m-5'>
            <PopularBar />
          </div>
          <div className='m-5'>
            <Posts />
          </div>
        </div>
      </main>

      <footer>
        
      </footer>
    </div>
  );
}

export default App;
