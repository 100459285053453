import React , { useState }from 'react';
import { motion } from "framer-motion";

import moment from 'moment';

import { IoChatboxEllipsesOutline, IoChevronUpCircleOutline, IoChevronDownCircleOutline } from "react-icons/io5";

function Card(props) {
  
  const { post, onToggleComments } = props;

  const [ showCom, setShowCom ] = useState(false);

  const shortenNumber = (num, digits) => {
    const units = ['k', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y'];
  
    for (let i = units.length - 1; i >= 0; i -= 1) {
      const decimal = 1000 ** (i + 1);
  
      if (num <= -decimal || num >= decimal) {
        return +(num / decimal).toFixed(digits) + units[i];
      }
    }
  
    return num;
  };


  const handleShowComment = () => {
    if (showCom === true) {
      setShowCom(false);
    }else{
      onToggleComments(post.permalink);
      setShowCom(true);
    }
  };

  const renderComments = () => {
    if (post.errorComments) {
      return (
        <div>
          <h3>Error loading comments</h3>
        </div>
      );
    }

    if (post.loadingComments) {
      return (
        <>
          <p className='placeholder-glow m-3'>

              <span className="placeholder w-100 mb-2 bg-danger"></span>
              <span className="placeholder w-100 mb-2 bg-warning"></span>
              <span className="placeholder w-75 mb-2 bg-danger"></span>

              <span className="placeholder w-20 mb-2 bg-info"></span>
              <span className="placeholder w-50 mb-2 bg-success"></span>

              <hr className='m-3'/>
          </p>

          <p className='placeholder-glow m-3'>

            <span className="placeholder w-100 mb-2 bg-danger"></span>
            <span className="placeholder w-100 mb-2 bg-warning"></span>
            <span className="placeholder w-75 mb-2 bg-danger"></span>

            <span className="placeholder w-20 mb-2 bg-info"></span>
            <span className="placeholder w-50 mb-2 bg-success"></span>

            <hr className='m-3'/>
          </p>
        </>
      );
    }

    if (post.showingComments) {
      return (
        <>
        {post.comments.map((comment) => (
          <div key={comment.id}>
            <div className='row'>
              <p className='m-3'>{comment.body}</p>
            </div>
            <div className='row text-center'>
              <p className='col color-redd'>u/{comment.author}</p>
              <p className='col'>{moment.unix(comment.created_utc).fromNow()}</p>
            </div>
            <hr className='m-3'/>
          </div>
        ))}
        </>
      );
    }

    return null;
  };

  return (
    <>
    <motion.div className='postCard row border border-2 border-dark rounded-4 shadow bg-white mb-5' initial={{ x: 300, opacity: 0 }} animate={{ x: 0, opacity: 1 }} whileHover={{ scale: 1.03 }}>

      <div className='row'>

        <div className='col-md-2 d-flex justify-content-center'>
          <div className='d-flex flex-column align-self-center'>
            <div className='text-center'>
              <IoChevronUpCircleOutline size={50}/>
              <p className='m-1 fs-3 color-redd'>{shortenNumber(post.ups, 1)}</p>
              <IoChevronDownCircleOutline size={50}/>
            </div>
          </div>
        </div>

        <div className='col-md-10 d-flex justify-content-center'>

          <div className='d-flex flex-column text-center'>
            <h2 className='m-3 text-center'>{post.title}</h2>
            
            <div className='col-md-12 d-flex justify-content-center m-3'>
              <img src={post.url} alt='' className='img-fluid rounded-4' width={450}/>
              
            </div>

            <hr className='m-4'/>

            <div className='d-flex justify-content-evenly m-1'>
              <p>Posted by <span className='color-redd'>u/{post.author}</span></p>
              <p><span className='text-secondary'>{moment.unix(post.created_utc).fromNow()}</span></p>
              <button onClick={handleShowComment} className='btn-comment'><IoChatboxEllipsesOutline size={24}/> {post.num_comments}</button>
            </div>

          </div>


        </div>
      </div>

    </motion.div>

    {showCom === true ? 
      <div className='comPostCard border border-2 border-dark rounded-4 shadow bg-white ms-5 me-5'>
        <div className='container'>

          <div className='row text-center m-3'>
            <div className='col-md-10'>
              <h3><IoChatboxEllipsesOutline size={24}/> Comments</h3>
            </div>
            <div className='col-md-2'>
              <button onClick={handleShowComment} className='btn btn-danger  rounded-circle'> X </button>
            </div>
          </div>

          <hr className='m-3'/>

          <div className='comments m-4'>
            {renderComments()}
          </div>

        </div>
      </div> 
    : ''}
    </>

  );
}

export default Card;
