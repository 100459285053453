import React, { useState, useEffect }  from 'react';
import { IoLogoReddit } from "react-icons/io";
import { motion } from "framer-motion";

import { useDispatch, useSelector } from 'react-redux';
import { setSearchTerm, setSort } from '../../store/redditSlice';

function SearchBar() {

  const [searchTermLocal, setSearchTermLocal] = useState('');
  const searchTerm = useSelector((state) => state.reddit.searchTerm);
  const dispatch = useDispatch();

  const onSearchTermChange = (e) => {
    setSearchTermLocal(e.target.value);
  };

  useEffect(() => {
    setSearchTermLocal(searchTerm);
  }, [searchTerm]);

  const onSearchTermSubmit = (e) => {
    e.preventDefault();
    dispatch(setSearchTerm(searchTermLocal));
  };

  const setHot = (e) => {
    e.preventDefault();
    dispatch(setSort(e.target.value));
  };

  return (
    <motion.div className='searchBar row border border-2 border-dark rounded-4 shadow bg-white' initial={{ y: -200, opacity: 0 }} animate={{ y: 0, opacity: 1 }} whileHover={{ scale: 1.01 }}>

      <div className='col'>
        <div class="m-3 text-center">
          <form onSubmit={onSearchTermSubmit}>
            <input type="text" onChange={onSearchTermChange} value={searchTermLocal} className="form-control rounded-pill bg-search p-3 text-dark" placeholder="Search..."/>
          </form>
        </div>
      </div>

      <div className='col-md-6'>
        <div className='m-3 text-center'>
          <h1 className='fs-1'><IoLogoReddit size={70}/> <span className='color-redd'>Redd</span>min</h1>
        </div>
      </div>
      <div className='col'>

        <div className="m-3 text-center">
          <button type="button" className="btn bg-sort dropdown-toggle rounded-pill p-3 text-dark" data-bs-toggle="dropdown" aria-expanded="false">
            Sort
          </button>

          <ul className="dropdown-menu">
            <li><button onClick={setHot} value={'hot'} className="dropdown-item">HOT</button></li>
            <li><button onClick={setHot} value={'new'} className="dropdown-item">NEW</button></li>
            <li><button onClick={setHot} value={'top'} className="dropdown-item">TOP</button></li>
            <li><button onClick={setHot} value={'rising'} className="dropdown-item">RISING</button></li>
          </ul>
        </div>

      </div>
    </motion.div>
  );
}

export default SearchBar;
