import React from 'react';

import { IoChevronUpCircleOutline, IoChevronDownCircleOutline } from "react-icons/io5";

function CardLoading() {
  

  return (
    <>
      <div className='postCard row border border-2 border-dark rounded-4 shadow bg-white mb-5' initial={{ x: 300, opacity: 0 }} animate={{ x: 0, opacity: 1 }} whileHover={{ scale: 1.03 }}>

        <div className='row'>

          <div className='col-md-2 d-flex justify-content-center'>
            <div className='d-flex flex-column align-self-center'>
              <div className='text-center'>
                <IoChevronUpCircleOutline size={50}/>

                  <p className='placeholder-glow'>
                    <span className="placeholder col-12 placeholder-lg bg-warning"></span>
                    <span className="placeholder col-12 placeholder-lg bg-warning"></span>
                  </p>

                <IoChevronDownCircleOutline size={50}/>
              </div>
            </div>
          </div>

          <div className='col-md-10'>

          <p className="placeholder-glow space m-5">
            <span className="placeholder col-12 placeholder-lg bg-danger"></span>
            <span className="placeholder col-12 placeholder-lg bg-warning"></span>
          </p>
          <p className="placeholder-glow space m-5">
            <span className="placeholder col-12 placeholder-lg bg-success"></span>
            <span className="placeholder col-12 placeholder-lg bg-info"></span>
            <span className="placeholder col-12 placeholder-lg bg-success"></span>
            <span className="placeholder col-12 placeholder-lg bg-info"></span>
            <span className="placeholder col-12 placeholder-lg bg-success"></span>
            <span className="placeholder col-12 placeholder-lg bg-info"></span>
          </p>

          <p className="placeholder-glow space m-5">
            <span className="placeholder col-12 placeholder-lg bg-dark"></span>
            <span className="placeholder col-12 placeholder-lg bg-info"></span>
          </p>

          </div>
        </div>

      </div>

    </>

  );
}

export default CardLoading;
