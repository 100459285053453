import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Card from '../../components/card/Card';
import CardLoading from '../../components/card/CardLoading';

import {
  fetchPosts,
  selectFilteredPosts,
  setSearchTerm,
  fetchComments,
} from '../../store/redditSlice';


function Home() {
  const reddit = useSelector((state) => state.reddit);
  const { isLoading, error, searchTerm, selectedSubreddit, sort } = reddit;
  const posts = useSelector(selectFilteredPosts);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchPosts(selectedSubreddit, sort));
  }, [selectedSubreddit, sort]);

  function onToggleComments(index) {
    const getComments = (permalink) => {
      dispatch(fetchComments(index, permalink));
    };

    return getComments;
  }

  if (isLoading) {
    return (
      <>
        <CardLoading />
        <CardLoading />
        <CardLoading />
      </>
    );
  }

  if (posts.length === 0) {
    return (
      <div className="card p-5">
        <h2>No posts matching "{searchTerm}"</h2>
        <button type="button" className='btn btn-info' onClick={() => dispatch(setSearchTerm(''))}>
          Go home
        </button>
      </div>
    );
  }

  if (error) {
    return (
      <div className="card p-5">
        <h2>Failed to load posts.</h2>
        <button
          type="button"
          className='btn btn-success'
          onClick={() => dispatch(fetchPosts(selectedSubreddit))}
        >
          Try again
        </button>
      </div>
    );
  }

  return (
    <>
        {posts.map((post, index) => (
          
          <Card post={post} key={post.id} onToggleComments={onToggleComments(index)}/>

        ))}   
    </>
  );
}

export default Home;
