import React, {useEffect}from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { motion } from "framer-motion";

import { fetchSubreddits, selectSubreddits } from '../../store/subRedditSlice';

import {
  setSelectedSubreddit,
} from '../../store/redditSlice';

function PopularBar() {

  const dispatch = useDispatch();
  const subreddits = useSelector(selectSubreddits);

  useEffect(() => {
    dispatch(fetchSubreddits());
  }, [dispatch]);

  return (
    <div className='popular row border border-2 border-dark rounded-4 shadow bg-white'>

      <div className='d-flex justify-content-center'>
        <div className='m-3 text-left'>
          <h3 className='fs-1'>Popular <br/>Sub<span className='color-redd'>redd</span>its</h3>
        </div>
      </div>

      <div className='d-flex justify-content-center'>
        <div className='col-md-8'>
          <hr/>
        </div>
      </div>

      <div className='d-flex flex-column'>
        {subreddits.map((subreddit) => (
          <motion.button 
            initial={{ x: -300, opacity: 0 }} 
            animate={{ x: 0, opacity: 1 }} 
            whileHover={{ scale: 1.03 }} 
            onClick={() => dispatch(setSelectedSubreddit(subreddit.url))} 
            key={subreddit.id} 
            type="button" 
            className="m-3 btn rounded-pill bg-popular fs-4"
          >
            r/{subreddit.display_name}

            <img
                src={
                  subreddit.icon_img || `https://api.dicebear.com/8.x/bottts/png`
                }
                alt={`${subreddit.display_name}`}
                className="rounded-circle ms-2"
                width={50}
                height={50}
              />
          </motion.button> 
        )
        )}        
      </div>

    </div>
  );
}

export default PopularBar;
